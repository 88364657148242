<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <el-form :model="formData" ref="ruleForm" class="demo-ruleForm">
      <el-form-item prop="code">
        <el-input
          type="textarea"
          id="textarea"
          disabled
          placeholder="代码展示区"
          v-model="formData.code"
        />
      </el-form-item>
    </el-form>
    <el-button class="copy_btn" type="primary" :data-clipboard-text="formData.code" @click="copy">复制代码</el-button>
    <span class="copy">此代码适用于PC端页面，请将此代码复制并粘贴到您要赋码的每个网页中。</span>
    <div class="tip">
      <div><i class="iconfont icon-shuoming" />说明：代码安装说明</div>
      <div class="content">
        <div>
          <span>1.请将代码添加到网站全部页面的&lt;/head>标签前。</span>
          <span>2.建议在header.htm类似的页头模板页面中安装，以达到一处安装，全站皆有的效果。</span>
          <span>3.如果插件代码安装正确，网站被访问后即可立即自动赋码，在标识码管理中可查看新赋码内容，同时可在国家顶级节点标识查询系统实时查询。</span>
        </div>
        <div>
          <span>小贴士：</span>
          <span>1.此代码不会影响您网站页面的显示，请您放心使用。</span>
          <span>2.如果您有技术上的疑问，可咨询您的网站管理员、托管公司，或者拨打4000033166咨询。</span>
        </div>
      </div>
    </div>
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import Clipboard from "clipboard";
import { companyApi } from "@/services/api";
export default {
  components: {
    PageContainer
  },
  data() {
    return {
      formData: {
        code: ""
      }
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      let { code, data } = await companyApi.handlePlugin();
      console.log(data);
      if (code == 200) {
        this.formData.code = data;
      }
    },
    copy() {
      // let clipboard = new this.Clipboard(".copy_btn");
      // clipboard.on("success", () => {
      //   this.$message.success("复制成功");
      //   clipboard.destroy();
      // });
      // clipboard.on("error", () => {
      //   this.$message.error("复制失败");
      //   clipboard.destroy();
      // });
      let input = document.createElement("textarea");
      input.value = this.formData.code;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.$message.success("复制成功");
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-header {
  display: flex;
  align-items: center;
}
/deep/.el-input .el-input__inner {
  width: 50% !important;
}
/deep/.el-radio {
  margin-right: 100px;
}
/deep/.el-textarea .el-textarea__inner {
  width: 790px;
  font-size: 14px;
  height: 243px;
  padding: 23px 17px;
  background-color: #cecece;
  color: #000;
}
/deep/.el-textarea .el-textarea__inner::placeholder {
  text-align: center;
  line-height: 218px;
  color: #000;
}
.copy_btn {
  margin-bottom: 31px;
}
.copy {
  margin-left: 18px;
  font-size: 14px;
  color: #7b7e8c;
}
.tip {
  display: flex;
  margin-left: 0;
  font-size: 14px;
  color: #7b7e8c;
  flex-direction: column;
  line-height: 30px;
  .content {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 20px;
    div {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
}
.icon-shuoming {
  color: #1285d6;
  margin-right: 4px;
}
</style>
